import * as React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout";
import Map from "../components/map"
import Contact from "../components/Contact";

const LandingPage = () => {
    return (
        <div>
            <Layout>
                <div className="mt-12">
                    <Contact />
                </div>
            </Layout>
        </div>
    )
}

export const Head = () => <Seo title="Home" />

export default LandingPage